import React, {useState, useRef, useEffect} from "react"
import {get, includes, isFinite} from "lodash"
import className from "classnames"
import * as queryString from "query-string"
import MobileDetect from "mobile-detect"

import {axios, tt} from "@lib/helpers"

import {formDataType, defaultGmoCardType} from "types/pointPurchase"

import {newCardStateType} from "types/creditCard"

import initCardState from "states/initCardState"

import {
  validateInput,
  newFormInputs,
  cardFormData,
} from "utils/creditCardValidator"
import fetchCardToken from "utils/fetchCardToken"
import DefaultCardLink from "components/creditCard/DefaultCardLink"
import {FormInputs} from "components/creditCard/FormInputs"

type AppGmoPurchaseRecordProps = {
  data: {
    shopid: string
    section: Map<string, string>
    dictionary: Map<string, string>
    t: any
    user_has_points: number
    point_rate: number
    recording_fee: number
    pay_by_card_point_purchases_path: string
    point_purchases_path: string
    gmo_callback_url: string
    payments_path: string
    purchase_recording_booking_path: string
    default_gmo_card: defaultGmoCardType
  }
}
const AppGmoPurchaseRecord = ({data}: AppGmoPurchaseRecordProps) => {
  const {
    shopid,
    section,
    dictionary,
    t,
    user_has_points,
    point_rate,
    recording_fee,
    pay_by_card_point_purchases_path,
    point_purchases_path,
    gmo_callback_url,
    payments_path,
    purchase_recording_booking_path,
    default_gmo_card,
  } = data
  const pointDiff = user_has_points - recording_fee
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [newCardState, setNewCardState] = useState<newCardStateType | null>(
    initCardState
  )
  const paypalRef = React.useRef(null)

  useEffect(() => {
    setHasErrors(
      !!newCardState.cardNameErrors ||
        !!newCardState.cardNumberErrors ||
        !!newCardState.cardExpiryErrors ||
        !!newCardState.cardCvcErrors
    )
    // @ts-ignore
    if (typeof paypal != "undefined" && paypal) {
      if (window.paypalButton) {
        window.paypalButton.close()
      }
      // @ts-ignore
      window.paypalButton = paypal.Buttons({
        style: {
          height: 55,
        },
        createOrder: () => {
          return axios
            .post("/paypal_payments", {
              point_to_purchase: Math.abs(pointDiff),
            })
            .then((res: any) => {
              return res?.data?.data?.order_id
            })
            .catch(() => {
              window.location.reload()
            })
        },
        onApprove: (data: any) => {
          setIsLoading(true)
          axios
            .post("/paypal_payments/capture", {
              order_id: data?.orderID,
            })
            .finally(() => {
              window.location.reload()
            })
        },
      })
      window.paypalButton.render(paypalRef.current)
    }
  }, [newCardState])
  const book = () => {
    axios.post(purchase_recording_booking_path).finally(() => {
      window.location.reload()
    })
  }
  const purchasePoint = (path: string, formData: formDataType) => {
    setIsLoading(true)
    axios
      .post(path, formData)
      .then((res2) => {
        const id = get(res2, "data.data.id")

        const poller = () => {
          axios.get(`/point_purchases/${id}`).then((res3) => {
            if (includes(["success"], get(res3, "data.data.status"))) {
              book()
            } else if (includes(["error"], get(res3, "data.data.status"))) {
              window.location.reload()
            } else if (
              includes(["tds_processing"], get(res3, "data.data.status"))
            ) {
              window.location.href = get(res3, "data.data.gmo_redirect_url")
            } else {
              window.setTimeout(() => {
                poller()
              }, 1000)
            }
          })
        }

        poller()
      })
      .catch((error: any) => {
        window.location.reload()
      })
  }
  const purchasePointByDefault = () => {
    purchasePoint(point_purchases_path, defaultCardFormData)
  }
  const purchasePointByNew = (token: string) => {
    purchasePoint(pay_by_card_point_purchases_path, newCardFormData(token))
  }
  const submitCardFormData = (token: string) => {
    purchasePointByNew(token)
  }
  const onFormInputsChangeHandler = (value: string, id: string) => {
    validateInput(value, id, newCardState, setNewCardState)
  }
  const defaultCardFormData: formDataType = {
    point_purchase: {
      point_to_purchase: Math.abs(pointDiff),
      gmo_callback_url: gmo_callback_url,
    },
  }
  const newCardFormData = (token: string) => ({
    point_purchase: {
      point_to_purchase: Math.abs(pointDiff),
      gmo_callback_url: gmo_callback_url,
    },
    card: cardFormData(token),
  })

  return (
    <React.Fragment>
      <div className="AppPurchaseRecord__required-points">
        <p className="AppPurchaseRecord__required-points__p">
          {get(t, "current.required_points.p")}
        </p>

        <span className="AppPurchaseRecord__required-points__span">
          {recording_fee}pt
        </span>
      </div>
      {pointDiff >= 0 ? (
        <form
          className="AppPurchaseRecord__buttons"
          onSubmit={(e) => {
            e.preventDefault()

            setIsLoading(true)
            axios.post(purchase_recording_booking_path).finally((e) => {
              window.location.reload()
            })
          }}
        >
          <button className="Button Button--large" disabled={isLoading}>
            {isLoading
              ? get(t, "dictionary.loading")
              : get(t, "current.submit")}
          </button>
        </form>
      ) : (
        <div className="AppPurchaseRecord__point">
          <h3 className="AppPurchaseRecord__point__h3">
            {get(t, "current.point.h3")}
          </h3>

          <p className="AppPurchaseRecord__point__p">
            {tt(t, "current.point.p", {point: user_has_points})}
          </p>

          <p className="AppInput">
            {Math.abs(pointDiff)}pt: {Math.abs(pointDiff * point_rate)}
            {get(t, "current.point.yen")}
          </p>

          <div className="AppBookingsReserve__buttons__row">
            <div ref={paypalRef} className="AppBookingsReserve__button"></div>
          </div>

          <h3 className="AppPurchaseRecord__point__h3">
            {get(t, "current.point.payment_method")}
          </h3>

          {default_gmo_card ? (
            <form
              className={className("AppForm", {"AppForm--overlay": isLoading})}
              onSubmit={(e) => {
                e.preventDefault()
                purchasePointByDefault()
              }}
            >
              <DefaultCardLink
                path={payments_path}
                img={get(default_gmo_card, "img")}
                last4={get(default_gmo_card, "last4")}
                brand={get(default_gmo_card, "brand")}
              />

              <div className="AppForm__row">
                <button className="Button Button--large" disabled={isLoading}>
                  {isLoading
                    ? get(dictionary, "loading")
                    : get(t, "current.submit")}
                </button>
              </div>
            </form>
          ) : (
            <form
              className={className("AppForm", {
                "AppForm--overlay": isLoading,
              })}
              onSubmit={(e) => {
                e.preventDefault()

                setIsLoading(true)
                fetchCardToken(shopid, newCardState, submitCardFormData)
              }}
            >
              <FormInputs
                formInputs={newFormInputs(section, newCardState)}
                formLabels={dictionary}
                onChangeHandler={onFormInputsChangeHandler}
              />
              <div className="AppForm__row">
                <button
                  className="Button Button--large"
                  disabled={isLoading || hasErrors}
                >
                  {isLoading
                    ? get(dictionary, "loading")
                    : get(t, "current.submit")}
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default AppGmoPurchaseRecord
