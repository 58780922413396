import React from "react"
import get from "lodash/get"
import includes from "lodash/includes"
import image from "../../../../images/application2/OrdersProcessing.png"
import {axios, tt} from "@lib/helpers"

const AppPurchaseRecordGmoSecure: ReactFC<PropTypes> = ({data}) => {
  const {paths, point_purchase, t} = data

  const book = () => {
    axios.post(paths.purchase_recording_booking_path).finally(() => {
      window.location.href = paths.purchase_recording_booking_path
    })
  }

  const poller = () => {
    axios
      .get(paths.point_purchase_path)
      .then((res: any) => {
        if (includes(["success"], res?.data?.data?.status)) {
          book()
        } else if (includes(["error"], res?.data?.data?.status)) {
          window.location.href = paths.purchase_recording_booking_path
        } else {
          window.setTimeout(() => {
            poller()
          }, 1000)
        }
      })
      .catch((error: any) => {
        window.location.href = paths.purchase_recording_booking_path
      })
  }

  poller()

  return (
    <div className="AppSecureTransaction">
      <h1 className="App__h1">{tt(t, "title")}</h1>
      <div className="AppSecureTransaction__body">
        <img
          className="AppSecureTransaction__body__img AppSecureTransaction__body__img--animate"
          src={image}
          alt="Order processing"
          style={{
            width: "82px",
            height: "82px",
          }}
        />

        <p className="AppSecureTransaction__body__p">{tt(t, "p1")}</p>

        <p className="AppSecureTransaction__body__p AppSecureTransaction__body__p--red">
          {tt(t, "p2")}
        </p>
      </div>
    </div>
  )
}

export default AppPurchaseRecordGmoSecure
