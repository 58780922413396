import {get} from "lodash"

import {NoNameFunctionReturnVoid} from "types/common"
import {newCardStateType, inputLabelsType} from "types/creditCard"

const validateLength = (
  value: string,
  key: string,
  state: newCardStateType,
  setState: NoNameFunctionReturnVoid
) => {
  if (value.length >= 0) {
    setState({...state, [key]: value, [key + "Errors"]: null})
  } else {
    setState({...state, [key + "Errors"]: "invalid_card_name"})
  }
}

const validateCardExpiry = (
  value: string,
  key: string,
  state: newCardStateType,
  setState: NoNameFunctionReturnVoid
) => {
  if (!value) {
    setState({
      ...state,
      [key]: value,
      [key + "Errors"]: null,
    })
    return
  }

  const realValue = value
    .replace(/\D/g, "")
    .split(/(.{2})/)
    .filter((O) => O)
    .filter((e, i, a) => i === 0 || i === a.length - 1)
    .join("")
  if (validCardExpiry(realValue)) {
    setState({
      ...state,
      [key]: realValue,
      [key + "Errors"]: null,
    })
    const nextSibling = document.getElementById("cardCvc")
    if (nextSibling !== null) {
      nextSibling.focus()
    }
  } else {
    setState({
      ...state,
      [key]: realValue,
      [key + "Errors"]: "invalid_card_expiry",
    })
  }
}

const validateCardCvc = (
  value: string,
  key: string,
  state: newCardStateType,
  setState: NoNameFunctionReturnVoid
) => {
  const realValue = value.replace(/\D/g, "")
  if (value.match(/^\d{3,4}$/)) {
    setState({
      ...state,
      [key]: realValue,
      [key + "Errors"]: null,
    })
  } else {
    setState({
      ...state,
      [key]: realValue,
      [key + "Errors"]: "invalid_card_cvc",
    })
  }
}

const validateCreditCard = (
  value: string,
  key: string,
  state: newCardStateType,
  setState: NoNameFunctionReturnVoid
) => {
  if (!value) {
    setState({
      ...state,
      [key]: value,
      [key + "Errors"]: null,
    })
    return
  }

  const realValue = value.replace(/\D/g, "")
  if (getCardBrand(realValue)) {
    setState({
      ...state,
      [key]: realValue,
      [key + "Errors"]: null,
    })
    const nextSibling = document.getElementById("cardExpiry")
    if (realValue.length >= 16 && nextSibling !== null) {
      nextSibling.focus()
    }
  } else {
    setState({
      ...state,
      [key]: realValue,
      [key + "Errors"]: "invalid_card_number",
    })
  }
}

const masterRegex =
  /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/
const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/
const jcbRegex = /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
const amexRegex = /^3[47][0-9]{13}$/
const discoverRegex =
  /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/
const maestroRegex =
  /^(5018|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)[0-9]{8,15}$/

export const getCardBrand = (cardNumber: string): string => {
  if (cardNumber.match(masterRegex)) return "MasterCard"
  if (cardNumber.match(visaRegex)) return "Visa"
  if (cardNumber.match(jcbRegex)) return "JCB"
  if (cardNumber.match(dinersRegex)) return "Diners Club"
  if (cardNumber.match(amexRegex)) return "American Express"
  if (cardNumber.match(discoverRegex)) return "Discover"
  if (cardNumber.match(maestroRegex)) return "Maestro"

  return ""
}

const validCardExpiry = (cardExpiry: string): boolean => {
  if (cardExpiry.match(/^\d{4}$/)) {
    const expiryMonth = cardExpiry.match(/^\d{2}/)[0]
    if (Number(expiryMonth) < 1 && Number(expiryMonth) > 12) {
      return false
    }
    const expiryYear = "20" + cardExpiry.match(/\d{2}$/)[0]
    const expiryDate = new Date(expiryYear, expiryMonth)
    if (expiryDate > new Date()) {
      return true
    }
  }

  return false
}

export const validateInput = (
  value: string,
  id: string,
  state: newCardStateType,
  setState: NoNameFunctionReturnVoid
): void => {
  switch (id) {
    case "cardName":
      validateLength(value, "cardName", state, setState)
      break
    case "cardNumber":
      validateCreditCard(value, "cardNumber", state, setState)
      break
    case "cardExpiry":
      validateCardExpiry(value, "cardExpiry", state, setState)
      break
    case "cardCvc":
      validateCardCvc(value, "cardCvc", state, setState)
      break
    default:
      break
  }
}

export const newFormInputs = (
  t: Map<string, string>,
  state: newCardStateType
): inputLabelsType[] => {
  return [
    {
      label: get(t, "label"),
      value: get(state, "cardName"),
      errors: get(t, state.cardNameErrors),
      id: "cardName",
    },
    {
      label: get(t, "label2"),
      value: get(state, "cardNumber"),
      errors: get(t, state.cardNumberErrors),
      id: "cardNumber",
    },
    {
      label: get(t, "label3"),
      value: get(state, "cardExpiry"),
      errors: get(t, state.cardExpiryErrors),
      id: "cardExpiry",
    },
    {
      label: get(t, "label4"),
      value: get(state, "cardCvc"),
      errors: get(t, state.cardCvcErrors),
      id: "cardCvc",
    },
  ]
}

// export const cardFormData = (state: newCardStateType): Map<string, string> => ({
//   type: "card_number",
//   holder_name: state.cardName,
//   card_no: state.cardNumber,
//   expire: state.cardExpiry,
//   security_code: state.cardCvc,
// })
export const cardFormData = (token: string): Map<string, string> => ({
  type: "token",
  token: token,
})
