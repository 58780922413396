import React, {useState, useRef, useEffect} from "react"
import {get, includes, isFinite} from "lodash"
import className from "classnames"
import * as queryString from "query-string"
import MobileDetect from "mobile-detect"

import {axios, tt} from "@lib/helpers"

import {defaultGmoCardType} from "types/pointPurchase"

const pointOpts = [
  {
    value: 5,
  },
  {
    value: 20,
  },
  {
    value: 50,
  },
  {
    value: 100,
  },
  {
    value: 300,
  },
]
type AppBookingsGmoAfterProps = {
  data: {
    t: any
    name: string
    points: number
    point_rate: number
    payments_path: string
    gift_booking_path: string
    point_purchases_path: string
    gmo_callback_url: string
    default_gmo_card: defaultGmoCardType
  }
}
const AppBookingsGmoAfter = ({data}: AppBookingsGmoAfterProps) => {
  const {
    t,
    name,
    points,
    point_rate,
    payments_path,
    gift_booking_path,
    point_purchases_path,
    gmo_callback_url,
    default_gmo_card,
  } = data

  const customInput = React.createRef()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [isActiveCustom, setIsActiveCustom] = useState<boolean>(
    Boolean(get(queryString.parse(window.location.search), "custom"))
  )
  const [isChangingCustom, setIsChangingCustom] = useState<boolean>(false)
  const [customValue, setCustomValue] = useState<boolean>(
    get(queryString.parse(window.location.search), "custom")
      ? get(queryString.parse(window.location.search), "point")
      : 1
  )
  const [supportBrowserPayment, setSupportBrowserPayment] =
    useState<boolean>(false)
  const [isMobileChrome, setIsMobileChrome] = useState<boolean>(false)
  const paypalRef = React.useRef(null)

  const getPointsToGive = () => {
    return Number(
      isActiveCustom
        ? customValue
        : get(
            queryString.parse(window.location.search),
            "point",
            get(pointOpts, "0.value")
          )
    )
  }
  const getPointsToBuy = () => {
    return getPointsToGive() - points
  }

  const givePoints = () => {
    axios
      .post(gift_booking_path, {
        gift: {
          point: getPointsToGive(),
          message: message,
        },
      })
      .finally(() => {
        window.location.reload()
      })
  }

  const gmoCallbackUrl = () => {
    const url = new URL(gmo_callback_url)
    url.searchParams.append("point", getPointsToGive())
    url.searchParams.append("message", message)
    return url.toString()
  }

  const purchasePoint = (path: string, formData: formDataType) => {
    setIsLoading(true)
    axios
      .post(path, formData)
      .then((res: any) => {
        const id = res?.data?.data?.id

        const poller = () => {
          axios
            .get(`/point_purchases/${id}`)
            .then((res2: any) => {
              if (includes(["success", "error"], res2?.data?.data?.status)) {
                if (res2?.data?.data?.status == "error") {
                  alert(
                    `${get(t, "point_purchase_error")} (${
                      res2.data?.data?.charge_error_code
                    })`
                  )
                }
                window.location.reload()
              } else if (
                includes(["tds_processing"], res2?.data?.data?.status)
              ) {
                window.location.href = res2?.data?.data?.gmo_redirect_url
              } else {
                window.setTimeout(() => {
                  poller()
                }, 1000)
              }
            })
            .catch(() => {
              window.location.reload()
            })
        }

        poller()
      })
      .catch(() => {
        window.location.reload()
      })
  }

  useEffect(() => {
    // @ts-ignore
    if (typeof paypal != "undefined" && paypal) {
      if (window.paypalButton) window.paypalButton.close()
      // @ts-ignore
      window.paypalButton = paypal.Buttons({
        style: {
          height: 55,
        },
        createOrder: () => {
          return axios
            .post("/paypal_payments", {
              point_to_purchase: getPointsToBuy(),
            })
            .then((res) => {
              return get(res, "data.data.order_id")
            })
            .catch(() => {
              window.location.reload()
            })
        },
        onApprove: (data: any) => {
          setIsLoading(true)
          axios
            .post("/paypal_payments/capture", {
              order_id: data?.orderID,
            })
            .finally(() => {
              givePoints()
            })
        },
      })
      window.paypalButton.render(paypalRef.current)
    }
  }, [])

  return (
    <div
      className={className("AppBookingsShowAfterUser__form", {
        "AppForm--overlay": isLoading,
      })}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <div className="AppBookingsShowAfterUser__form__row">
        <h3 className="AppBookingsShowAfterUser__h3">
          {tt(t, "current.h3", {name: name})}
        </h3>

        <p className="AppBookingsShowAfterUser__small">
          {tt(t, "current.p", {points: points})}
        </p>
      </div>

      <div className="AppBookingsShowAfterPublisher__select-point">
        <div className="AppBookingsShowAfterPublisher__radios">
          <div className="AppBookingsShowAfterPublisher__radios__wrapper">
            {pointOpts.map((x, i) => (
              <div className="AppBookingsShowAfterPublisher__radio" key={i}>
                <a
                  className={className(
                    "AppBookingsShowAfterPublisher__radio__span",
                    {
                      "AppBookingsShowAfterPublisher__radio__span--active":
                        getPointsToGive() == get(x, "value") && !isActiveCustom,
                    }
                  )}
                  href={`?${queryString.stringify({
                    point: get(x, "value"),
                  })}`}
                  onClick={() => setIsLoading(true)}
                >
                  {get(x, "value")}pt
                </a>
              </div>
            ))}
          </div>
        </div>

        <div
          className="AppBookingsShowAfterPublisher__select-point__custom"
          onClick={() => {
            setIsActiveCustom(true)
            setIsChangingCustom(true)
            customInput?.current?.focus?.()
          }}
        >
          <a
            className={className("AppBookingsShowAfterPublisher__radio__span", {
              "AppBookingsShowAfterPublisher__radio__span--large": true,
              "AppBookingsShowAfterPublisher__radio__span--active":
                isActiveCustom,
            })}
          >
            {get(t, "payment_form.custom_amount")}
          </a>

          <input
            ref={customInput}
            type="text"
            className="AppInput"
            value={customValue}
            onChange={(e) => setCustomValue(e?.target?.value)}
            onBlur={(e) => {
              let _value = Number(get(e, "target.value"))

              if (!isFinite(_value) || _value < 1) {
                _value = 1
              }

              window.location.href = `?${queryString.stringify({
                point: _value,
                custom: 1,
              })}`
            }}
          />

          <span className="AppBookingsShowAfterPublisher__select-point__custom__span">
            pt
          </span>
        </div>
        {(() => {
          let _pointsToGive = getPointsToGive()

          if (!isFinite(_pointsToGive)) {
            _pointsToGive = 0
          }

          let _text = tt(t, "current.form.point_to_yen", {
            yen: (_pointsToGive * 100).toLocaleString(),
          })

          let _isError = false

          if (_pointsToGive > 1000) {
            _isError = true
            _text = tt(t, "current.form.point_upper_error")
          } else if (_pointsToGive < 1) {
            _isError = true
            _text = tt(t, "current.form.point_lower_error")
          }

          return (
            <p
              className={className(
                "AppBookingsShowAfterPublisher__select-point__p",
                {
                  "AppBookingsShowAfterPublisher__select-point__p--error":
                    _isError,
                }
              )}
            >
              {_text}
            </p>
          )
        })()}
      </div>

      <label className="AppBookingsShowAfterPublisher__label">
        <span className="AppBookingsShowAfterPublisher__label__span">
          {tt(t, "current.form.message")}
        </span>

        <textarea
          className="AppInput"
          placeholder={tt(t, "current.form.message_placeholder")}
          rows={3}
          value={message}
          onChange={(e) => setMessage(get(e, "target.value"))}
        />
      </label>

      {getPointsToBuy() > 0 && (
        <React.Fragment>
          <div className="AppBookingsShowAfterUser__form__row">
            <h3 className="AppBookingsShowAfterUser__h3">
              {tt(t, "point_purchase_form.purchase_point")}
            </h3>
            <p className="AppBookingsShowAfterUser__p">
              {tt(t, "point_purchase_form.explanation", {
                user_has_points: points,
              })}
            </p>
            <div className="AppBookingsReserve__buttons">
              <p className="AppInput">
                {getPointsToBuy()}pt: {getPointsToBuy() * point_rate}
                {tt(t, "bookings_reserve_yen")}
              </p>
              <div
                className={className("AppBookingsReserve__buttons__row", {
                  "AppBookingsReserve__buttons__row--pe-n": isChangingCustom,
                })}
              >
                <div
                  ref={paypalRef}
                  className="AppBookingsReserve__button"
                ></div>
              </div>
            </div>
          </div>

          <div className="AppBookingsReserve__box">
            <h3 className="AppBookingsReserve__h3">
              {get(t, "point_purchase_form.payment_info")}
            </h3>

            <a
              className="AppPayments__card AppPayments__card--single"
              href={payments_path}
            >
              {default_gmo_card ? (
                <React.Fragment>
                  <img
                    className="AppPayments__card__img"
                    src={get(default_gmo_card, "img")}
                  />

                  <div className="AppPayments__card__right">
                    <div className="AppPayments__card__right__top AppPayments__card__right__top--caret">
                      <div className="AppPayments__card__right__top__wrapper">
                        <div className="AppPayments__card__right__top__number">
                          **** **** **** {get(default_gmo_card, "last4")}
                        </div>

                        <div className="AppPayments__card__right__top__brand">
                          {get(default_gmo_card, "brand")}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="AppPayments__card__right__top AppPayments__card__right__top--caret AppPayments__card__right__top--gc-1-3">
                  <div className="AppPayments__card__right__top__wrapper">
                    <div className="AppPayments__card__right__top__number">
                      {get(t, "payment_form.link")}
                    </div>
                  </div>
                </div>
              )}
            </a>
          </div>
        </React.Fragment>
      )}
      <a
        className={className("Button", {
          "Button--large": true,
          "Button--pe-n": isChangingCustom,
          "Button--disabled":
            ((!default_gmo_card || isLoading) && getPointsToBuy() > 0) ||
            getPointsToGive() > 1000 ||
            getPointsToGive() < 1,
        })}
        onClick={() => {
          if (
            window.confirm(
              tt(t, "current.form.confirm", {
                point: getPointsToGive(),
              })
            )
          ) {
            setIsLoading(true)
            if (getPointsToBuy() > 0) {
              purchasePoint(point_purchases_path, {
                point_purchase: {
                  point_to_purchase: getPointsToBuy(),
                  gmo_callback_url: gmoCallbackUrl(),
                },
              })
            } else {
              givePoints()
            }
          }
        }}
      >
        {tt(t, "current.form.submit")}
      </a>
    </div>
  )
}

export default AppBookingsGmoAfter
